<template>
  <div class="container">
    <van-nav-bar
        left-text="入职指引"
        left-arrow fixed placeholder
        @click-left="$router.back()"
        v-if="isShow"
    />
    <div class="main">
      <h1 class="title">入职流程</h1>
      <div class="linear">
        <div class="item">
          <div class="img">
            <img src="../../../static/img/enter/1.png" alt="">
          </div>
          <p>面试审批通过</p>
        </div>
        <van-icon color="#e50012" class="arrow" name="arrow" />
        <div class="item">
          <div class="img">
            <img src="../../../static/img/enter/2.png" alt="">
          </div>
          <p>发送入职邀请</p>
        </div>
        <van-icon color="#e50012" class="arrow" name="arrow" />
        <div class="item">
          <div class="img">
            <img src="../../../static/img/enter/3.png" alt="">
          </div>
          <p>准备入职材料</p>
        </div>
        <van-icon color="#e50012" class="arrow" name="arrow" />
        <div class="item">
          <div class="img">
            <img src="../../../static/img/enter/4.png" alt="">
          </div>
          <p>办理入职手续</p>
        </div>
      </div>
      <div class="info_item one">
        <div class="item-title">
          <img class="icon" src="../../../static/img/enter/5.png" alt="">
          <div class="text">携带资料</div>
          <div class="bc"></div>
        </div>
        <div class="num">
          <p>请您携带以下材料于上午9点之前到公司办理报到:</p>
          <p><span>*</span>1.身份证（原件）</p>
          <p><span>*</span>2.学历证明（照片）</p>
          <p><span>*</span>3.民生银行银行卡（卡号）</p>
          <p><span>*</span>4.原单位离职证明或解除劳动合同证明</p>
          <p><span>*</span>5.户口本本页及索引页（照片）</p>
          <p><span>*</span>6.无犯罪记录证明（截图）</p>
          <p><span>*</span>7.信用报告:芝麻信用、微信支付分</p>
        </div>
      </div>
      <div class="info_item two">
        <div class="item-title">
          <img class="icon" src="../../../static/img/enter/6.png" alt="">
          <div class="text">人事咨询</div>
          <div class="bc"></div>
        </div>
        <div class="item">
          <p class="item-name">
            <img style="width: 20px;" class="iconImage" src="../../../static/img/enter/8.png" alt="">
            <span>总部办公区:</span>
          </p>
          <p class="info">地址:临沂市兰山区新明辉智慧仓储物流园1号办公楼</p>
          <p class="info">电话:18669971563</p>
        </div>
        <div class="item">
          <p class="item-name">
            <img class="iconImage" src="../../../static/img/enter/8.png" alt="">
            <span>河东办公区:</span>
          </p>
          <p class="info">地址:山东省临沂市沂河新区海关路名都国际北50米中关村临沂软件产业基地C区3楼C311、C312、C314、C316</p>
          <p class="info">电话:18669618193</p>
        </div>
        <div class="item">
          <p class="item-name">
            <img class="iconImage" src="../../../static/img/enter/8.png" alt="">
            <span>成都办公区:</span>
          </p>
          <p class="info">地址:四川省成都市武侯区武科西五路盈创创意中心2栋1206</p>
          <p class="info">电话:17780072547</p>
        </div>
        <div class="item">
          <p class="item-name">
            <img class="iconImage" src="../../../static/img/enter/8.png" alt="">
            <span>济南办公区:</span>
          </p>
          <p class="info">地址:山东省济南市高新区舜华路街道舜华路舜泰广场8号楼东座2105</p>
          <p class="info">电话:15318563558</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "enter",
  data(){
    return {
      isShow : true
    }
  },
  created() {
    let i = window.history.length;
    if(i == 1){
      this.isShow = false;
    }
  }
}
</script>

<style scoped lang="scss">
.main{
  box-sizing: border-box;
  padding: 0 18px;
  background: url("../../../static/img/enter/7.png") no-repeat 0% center;
  background-size: contain;
  .title{
    font-family: PingFang-SC-Medium;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #000;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 17px;
    padding-bottom: 17px;
    border-bottom: 1px solid #575352;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 49px;
      height: 3px;
      background-color: #e50012;
      left: 50%;
      transform: translateX(-50%);
      bottom: -1px;
    }
  }
  .linear{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 25px;
    .item{
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .img{
        width: 58px;
        height: 58px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      p{
        font-family: PingFang-SC-Medium;
        font-size: 12px;
        font-weight: 700;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #333333;
        margin-top: 8px;
        margin-bottom: 0;
        white-space: nowrap;
      }
    }
    .arrow{
      flex-shrink: 0;
      margin-top: -20px;
      font-size: 16px;
    }
  }
  .info_item{
    .item-title{
      position: relative;
      display: flex;
      height: 26px;
      .text{
        width: 36%;
        flex-shrink: 0;
        background-color: #e50012;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        box-sizing: border-box;
        padding-left: 4px;
        line-height: 26px;
        text-align: center;
      }
      .bc{
        background-color: #e2e2e2;
        height: 100%;
        flex: 1;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          left: 0px;
          top: 0;
          width: 15px;
          height: 100%;
          background-color: #e2e2e2;
          transform: skew(45deg);
        }
        &:before{
          content: '';
          position: absolute;
          left: -13px;
          top: -2px;
          width: 15px;
          height: 110%;
          background-color: #fff;
          transform: skew(45deg);
        }
      }
      .icon{
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
      }
    }
    .item{
      margin-top: 15px;
      line-height: 1.75;
      color: #666;
      .item-title{
        font-size: 16px;
        padding-bottom: 5px;
      }
      .info{
        padding-left: 10px;
        padding-bottom: 1px;
      }
    }
  }
  .one{
    padding-top: 20px;
    .num{
      margin: 15px 0 20px;
      p{
        font-family: PingFang-SC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 28px;
        letter-spacing: 1px;
        color: #666666;
        display: flex;
        margin: 0;
        padding-left: 10px;
        &:first-child{
          padding-left: 0;
        }
        span{
          color: red;
          font-weight: 700;
          margin-right: 2px;
        }
      }
    }
  }
  .two{
    padding-bottom: 30px;
    p{
      font-family: PingFang-SC-Medium;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 23px;
      text-align: justify;
      letter-spacing: 0px;
      color: #333333;
      margin: 0;
    }
    .item-name{
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 2px;
      span{
        margin-left: 4px;
      }
      .iconImage{
        width: 20px;
      }
    }
  }
}
::v-deep .van-nav-bar__arrow{
  font-size: 19px;
  color: #666666;
}
::v-deep .van-nav-bar__text{
  font-family: PingFang-SC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}
</style>
